<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_manager_name')"></label>
          <span class="required-star">*</span>
          <input
            id="dealerName"
            v-model="dealer.dealerName"
            name="Dealer Number"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.dealerName = ''"
          />
          <span v-if="formErrors.dealerName" class="invalid-field">{{ formErrors.dealerName }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_field_password')"></label>
          <span class="required-star">*</span>
          <input
            v-model="dealer.password"
            type="password"
            class="form-control"
            placeholder
            @keyup="formErrors.password = ''"
          />
          <span v-if="formErrors.password" class="invalid-field">{{ formErrors.password }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_reg_number')"></label>
          <span v-if="dealer.bulkUpload" class="required-star">*</span>
          <input
            id="businessRegNo"
            v-model="dealer.businessRegNo"
            v-validate="'alpha_num'"
            name="IC Number"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.businessRegNo = ''"
          />
          <span v-if="formErrors.businessRegNo" class="invalid-field">{{ formErrors.businessRegNo }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_bank_name')"></label>
          <span v-if="dealer.bulkUpload" class="required-star">*</span>
          <Multiselect
            ref="multiselect"
            v-model="dealer.bankId"
            placeholder="Select Bank"
            :searchable="true"
            :options="banks"
            label="text"
            track-by="id"
            select-label
            deselect-label
            :loading="getBanksIsLoading"
            @input="formErrors.bankId = ''"
          ></Multiselect>
          <span v-if="formErrors.bankId" class="invalid-field">{{ formErrors.bankId }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_bank_holder_name')"></label>
          <span v-if="dealer.bulkUpload" class="required-star">*</span>
          <input
            v-model="dealer.bankAccNam"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.bankAccNam = ''"
          />
          <span v-if="formErrors.bankAccNam" class="invalid-field">{{
            formErrors.bankAccNam
          }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_owner_name')"></label>
          <span class="required-star">*</span>
          <input
            id="ownerName"
            v-model="dealer.ownerName"
            v-validate="'alpha_num'"
            name="IC Number"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.ownerName = ''"
          />
          <span v-if="formErrors.ownerName" class="invalid-field">{{ formErrors.ownerName }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_manager_contact_number')"></label>
          <span class="required-star">*</span>
          <input
            id="contactNum"
            v-model="dealer.contactNum"
            name="Phone Number"
            type="number"
            class="form-control"
            placeholder
            @keyup="formErrors.contactNum = ''"
          />
          <span v-if="formErrors.contactNum" class="invalid-field">{{ formErrors.contactNum }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_allow_bulk_upload')"></label>
          <div class="form-group mb-0">
            <label class="switch" for="bulkUpload">
              <input id="bulkUpload" v-model="dealer.bulkUpload" type="checkbox" />
              <div class="slider round"></div>
            </label>
            <br>
            <span v-if="formErrors.bulkUpload" class="invalid-field">{{
              formErrors.bulkUpload
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_salesperson_email')"></label>
          <span class="required-star">*</span>
          <input
            v-model="dealer.email"
            type="email"
            class="form-control"
            placeholder
            @keyup="formErrors.email = ''"
          />
          <span v-if="formErrors.email" class="invalid-field">{{ formErrors.email }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="country"
            v-model="dealer.countryId"
            :searchable="false"
            :options="countryOptions"
            label="text"
            track-by="id"
            :loading="isCountriesLoading"
            :allow-empty="false"
            :show-labels="false"
            @input="formErrors.country = ''; getBanks()"
          ></Multiselect>
          <span v-if="formErrors.country" class="invalid-field">{{ formErrors.country }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_lpp_code')"></label>
          <span class="required-star">*</span>
          <input
            id="prmLppName"
            v-model="dealer.prmLppName"
            name="LPP Code"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.prmLppName = ''"
          />
          <span v-if="formErrors.prmLppName" class="invalid-field">{{ formErrors.prmLppName }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_bank_acc_number')"></label>
          <span v-if="dealer.bulkUpload" class="required-star">*</span>
          <input
            v-model="dealer.bankAccNum"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.bankAccNum = ''"
          />
          <span v-if="formErrors.bankAccNum" class="invalid-field">{{
            formErrors.bankAccNum
          }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_ic_number')"></label>
          <span class="required-star">*</span>
          <input
            id="nationalId"
            v-model="dealer.nationalId"
            v-validate="'alpha_num'"
            name="IC Number"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.nationalId = ''"
          />
          <span v-if="formErrors.nationalId" class="invalid-field">{{ formErrors.nationalId }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_owner_ic')"></label>
          <span class="required-star">*</span>
          <input
            id="ownerIcOrId"
            v-model="dealer.ownerIcOrId"
            v-validate="'alpha_num'"
            name="IC Number"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.ownerIcOrId = ''"
          />
          <span v-if="formErrors.ownerIcOrId" class="invalid-field">{{ formErrors.ownerIcOrId }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_account_manager')"></label>
          <span class="required-star">*</span>
          <input
            id="accountManager"
            v-model="dealer.accountManager"
            name="Account Manager"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.accountManager = ''"
          />
          <span v-if="formErrors.accountManager" class="invalid-field">{{ formErrors.accountManager }}</span>
        </div>
      </div>
    </div>
    <div class="w-100 text-right mt-4">
      <button class="btn btn-sm btn-primary" :disabled="isDealerSubmitting" @click="submitDealer" v-text="$ml.get('button_submit_text')"></button>
    </div>
  </div>
</template>
<script>
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { DEALER_REGISTRATION_SUBMIT } from "../../store/modules/admin/actions";
import { phoneValidate, emailValidate, passwordValidate } from "../../utils/helpers";
import Multiselect from "vue-multiselect";
import { BANK_LIST_REQUEST } from "../../store/modules/utility/actions";

export default {
  name: "DealerRegistration",
  components: {
    Multiselect: Multiselect,
  },
  props: {
    countryOptions: {type: Array, default: () => []},
    isCountriesLoading: {type: Boolean, default: false}
  },
  data(){
    return{
      dealer: {
        bulkUpload: false
      },
      formErrors: [],
      isDealerSubmitting: false,
      getBanksIsLoading: false,
      banks: []
    }
  },
  methods: {
    getBanks() {
      let pdata = { countryCode: this.dealer.countryId.code };
      this.getBanksIsLoading = true;
      this.$store.dispatch(BANK_LIST_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.banks = res.content;
        }
        this.getBanksIsLoading = false;
      });
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.dealer.email) {
        this.formErrors.email = this.$ml.get('form_dealer_email_required');
        error++;
      }

      if(this.dealer.email){
        if(!emailValidate(this.dealer.email)){
          this.formErrors.email = this.$ml.get('form_dealer_email_invalid');
          error++;
        }
      }

      if (!this.dealer.password) {
        this.formErrors.password = this.$ml.get('form_field_password_required');
        error++;
      }

      if (!this.dealer.countryId) {
        this.formErrors.country = this.$ml.get('form_outlet_country_required');
        error++;
      }

      if (!this.dealer.nationalId) {
        this.formErrors.nationalId = this.$ml.get('form_manager_ic_required');
        error++;
      }

      if (!this.dealer.businessRegNo && this.dealer.bulkUpload) {
        this.formErrors.businessRegNo = this.$ml.get('form_dealer_reg_number_required');
        error++;
      }

      if (!this.dealer.bankAccNum && this.dealer.bulkUpload) {
        this.formErrors.bankAccNum = this.$ml.get("form_dealer_acc_number_required");
        error++;
      }

      if (!this.dealer.bankAccNam && this.dealer.bulkUpload) {
        this.formErrors.bankAccNam = this.$ml.get("form_dealer_acc_holder_name_required");
        error++;
      }

      if (!this.dealer.bankId && this.dealer.bulkUpload) {
        this.formErrors.bankId = this.$ml.get("form_dealer_acc_holder_name_required");
        error++;
      }

      if (!this.dealer.prmLppName) {
        this.formErrors.prmLppName = this.$ml.get('form_dealer_lpp_code_required');
        error++;
      }

      if (!this.dealer.ownerName) {
        this.formErrors.ownerName = this.$ml.get('form_dealer_owner_name_required');
        error++;
      }

      if (!this.dealer.ownerIcOrId) {
        this.formErrors.ownerIcOrId = this.$ml.get('form_dealer_owner_ic_required');
        error++;
      }

      if (!this.dealer.dealerName) {
        this.formErrors.dealerName = this.$ml.get('form_manager_name_required');
        error++;
      }

      if (!this.dealer.contactNum) {
        this.formErrors.contactNum = this.$ml.get('form_salesperson_contact_number_required');
        error++;
      }
      if (!this.dealer.accountManager) {
        this.formErrors.accountManager = this.$ml.get('form_dealer_acc_manager_required');
        error++;
      }

      if (this.dealer.contactNum) {
        if(!phoneValidate(this.dealer.contactNum)){
          this.formErrors.contactNum = this.$ml.get('invalid_phone_number');
          error++;
        }
      }

      return !error;
    },
    submitDealer(e){
      this.isDealerSubmitting = true;
      if (!this.checkFormValidity(e)) {
        this.isDealerSubmitting = false;
        return;
      } 
      if(!passwordValidate(this.dealer.password)) {
        this.formErrors.password = this.$ml.get('form_field_password_validation');
        return
      }

      let dealer = {...this.dealer};
      dealer["countryId"] = this.dealer.countryId.id;
      if(this.dealer.bulkUpload){
        dealer["bankId"] = this.dealer.bankId.id;
      }
      this.$store.dispatch(DEALER_REGISTRATION_SUBMIT, dealer).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Dealer " + this.dealer.email + " is created",
            timeOut: true
          });
          this.dealer = {};
        }
        this.isDealerSubmitting = false;
      });
    }
  },
  mounted(){
  }
}
</script>
<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_manager_name')"></label>
          <span class="required-star">*</span>
          <input
            id="fullName"
            v-model="salesperson.fullName"
            name="Bank Number"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.fullName = ''"
          />
          <span v-if="formErrors.fullName" class="invalid-field">{{ formErrors.fullName }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_field_password')"></label>
          <span class="required-star">*</span>
          <input
            v-model="salesperson.password"
            type="password"
            class="form-control"
            placeholder
            @keyup="formErrors.password = ''"
          />
          <span v-if="formErrors.password" class="invalid-field">{{ formErrors.password }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_manager_ic_type')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="idType"
            v-model="salesperson.idType"
            :searchable="false"
            :options="idTypes"
            label="text"
            track-by="id"
            :allow-empty="false"
            :show-labels="false"
            @input="formErrors.idType = ''"
          ></Multiselect>
          <span v-if="formErrors.idType" class="invalid-field">{{ formErrors.idType }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_bank_name')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="multiselect"
            v-model="salesperson.bankId"
            placeholder="Select Bank"
            :searchable="true"
            :options="banks"
            label="text"
            track-by="id"
            select-label
            deselect-label
            :loading="getBanksIsLoading"
            @input="formErrors.bankId = ''"
          ></Multiselect>
          <span v-if="formErrors.bankId" class="invalid-field">{{ formErrors.bankId }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('dealer_code')"></label>
          <span class="required-star">*</span>
          <input
            id="dealerCode"
            v-model="salesperson.dealerCode"
            v-validate="'alpha_num'"
            name="IC Number"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.dealerCode = ''"
          />
          <span v-if="formErrors.dealerCode" class="invalid-field">{{ formErrors.dealerCode }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_gender')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="multiselect-gender"
            v-model="salesperson.genderId"
            placeholder="Select Gender"
            :searchable="true"
            :options="genders"
            label="text"
            track-by="id"
            select-label
            deselect-label
            :loading="isGendersLoading"
            @input="formErrors.genderId = ''"
          ></Multiselect>
          <span v-if="formErrors.genderId" class="invalid-field">{{ formErrors.genderId }}</span>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_salesperson_email')"></label>
          <span class="required-star">*</span>
          <input
            v-model="salesperson.username"
            type="email"
            class="form-control"
            placeholder
            @keyup="formErrors.username = ''"
          />
          <span v-if="formErrors.username" class="invalid-field">{{ formErrors.username }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="country"
            v-model="salesperson.countryId"
            :searchable="false"
            :options="countryOptions"
            label="text"
            track-by="id"
            :loading="isCountriesLoading"
            :allow-empty="false"
            :show-labels="false"
            @input="formErrors.country = ''; getBanks()"
          ></Multiselect>
          <span v-if="formErrors.country" class="invalid-field">{{ formErrors.country }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_ic_number')"></label>
          <span class="required-star">*</span>
          <input
            id="nationalId"
            v-model="salesperson.nationalId"
            v-validate="'alpha_num'"
            name="IC Number"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.nationalId = ''"
          />
          <span v-if="formErrors.nationalId" class="invalid-field">{{ formErrors.nationalId }}</span>
        </div>
        
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_salesperson_bank_acc_number')"></label>
          <span class="required-star">*</span>
          <input
            id="bankNumber"
            v-model="salesperson.bankNumber"
            name="Bank Number"
            type="number"
            class="form-control"
            placeholder
            @keyup="formErrors.bankNumber = ''"
          />
          <span v-if="formErrors.bankNumber" class="invalid-field">{{ formErrors.bankNumber }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_manager_contact_number')"></label>
          <span class="required-star">*</span>
          <input
            id="phoneNumber"
            v-model="salesperson.phoneNumber"
            name="Phone Number"
            type="number"
            class="form-control"
            placeholder
            @keyup="formErrors.phoneNumber = ''"
          />
          <span v-if="formErrors.phoneNumber" class="invalid-field">{{ formErrors.phoneNumber }}</span>
        </div>
      </div>
    </div>
    <div class="w-100 text-right mt-4">
      <button class="btn btn-sm btn-primary" :disabled="isSalespersonSubmitting" @click="submitSalesperson" v-text="$ml.get('button_submit_text')"></button>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { SALES_PERSON_REGISTRATION_SUBMIT } from "../../store/modules/admin/actions";
import { GENDER_LIST_REQUEST } from "../../store/modules/utility/actions";
import { BANK_LIST_REQUEST } from "../../store/modules/utility/actions";
import { phoneValidate, emailValidate, passwordValidate } from "../../utils/helpers";

export default {
  name: "SalespersonRegistration",
  components: {
    Multiselect: Multiselect,
  },
  props: {
    countryOptions: {type: Array, default: () => []},
    isCountriesLoading: {type: Boolean, default: false}
  },
  data(){
    return{
      salesperson: {
        idType: {text: "IC", id: "I"}
      },
      formErrors: [],
      isSalespersonSubmitting: false,
      genders: [],
      isGendersLoading: false,
      banks: [],
      getBanksIsLoading: false,
      idTypes: [
        {text: "IC", id: "I"},
        {text: "Passport", id: "P"}
      ]
    }
  },
  methods: {
    getBanks() {
      let pdata = { countryCode: this.salesperson.countryId.code };
      this.getBanksIsLoading = true;
      this.$store.dispatch(BANK_LIST_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.banks = res.content;
        }
        this.getBanksIsLoading = false;
      });
    },
    getGenders() {
      this.isGendersLoading = true;
      this.$store.dispatch(GENDER_LIST_REQUEST).then(res => {
        if (res.content && res.resultCode === 0) {
          this.genders = res.content;
          this.isGendersLoading = false;
        } else {
          this.isGendersLoading = false;
        }
      });
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.salesperson.username) {
        this.formErrors.username = this.$ml.get('form_dealer_email_required');
        error++;
      }

      if(this.salesperson.username){
        if(!emailValidate(this.salesperson.username)){
          this.formErrors.username = this.$ml.get('form_dealer_email_invalid');
          error++;
        }
      }

      if (!this.salesperson.password) {
        this.formErrors.password = this.$ml.get('form_field_password_required');
        error++;
      }

      if (!this.salesperson.countryId) {
        this.formErrors.country = this.$ml.get('form_outlet_country_required');
        error++;
      }

      if (!this.salesperson.nationalId) {
        this.formErrors.nationalId = this.$ml.get('form_manager_ic_required');
        error++;
      }

      if (!this.salesperson.dealerCode) {
        this.formErrors.dealerCode = this.$ml.get('form_dealer_code_required');
        error++;
      }

      if (!this.salesperson.genderId) {
        this.formErrors.genderId = this.$ml.get('form_salesperson_gender_required');
        error++;
      }

      if (!this.salesperson.bankId) {
        this.formErrors.bankId = this.$ml.get('form_salesperson_bank_name_required');
        error++;
      }

      if (!this.salesperson.bankNumber) {
        this.formErrors.bankNumber = this.$ml.get('form_salesperson_bank_acc_number_required');
        error++;
      }

      if (!this.salesperson.fullName) {
        this.formErrors.fullName = this.$ml.get('form_manager_name_required');
        error++;
      }

      if (!this.salesperson.phoneNumber) {
        this.formErrors.phoneNumber = this.$ml.get('form_salesperson_contact_number_required');
        error++;
      }

      if (this.salesperson.phoneNumber) {
        if(!phoneValidate(this.salesperson.phoneNumber)){
          this.formErrors.phoneNumber = this.$ml.get('invalid_phone_number');
          error++;
        }
      }

      return !error;
    },
    submitSalesperson(e){
      this.isSalespersonSubmitting = true;
      if (!this.checkFormValidity(e)) {
        this.isSalespersonSubmitting = false;
        return;
      } 
      if(!passwordValidate(this.salesperson.password)) {
        this.formErrors.password = this.$ml.get('form_field_password_validation');
        return
      }

      let salesperson = {...this.salesperson};
      salesperson["countryId"] = this.salesperson.countryId.id;
      salesperson["genderId"] = this.salesperson.genderId.id;
      salesperson["idType"] = this.salesperson.idType.id;
      salesperson["bankId"] = this.salesperson.bankId.id;
      this.$store.dispatch(SALES_PERSON_REGISTRATION_SUBMIT, salesperson).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Salesperson " + this.salesperson.username + " is created",
            timeOut: true
          });
          this.salesperson = {};
        }
        this.isSalespersonSubmitting = false;
      });
    }
  },
  mounted(){
    this.getGenders();
  }
}
</script>
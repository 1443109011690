<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_manager_name')"></label>
          <span class="required-star">*</span>
          <input
            id="fullName"
            v-model="promoter_leader.fullName"
            name="Bank Number"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.fullName = ''"
          />
          <span v-if="formErrors.fullName" class="invalid-field">{{ formErrors.fullName }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_field_password')"></label>
          <span class="required-star">*</span>
          <input
            v-model="promoter_leader.password"
            type="password"
            class="form-control"
            placeholder
            @keyup="formErrors.password = ''"
          />
          <span v-if="formErrors.password" class="invalid-field">{{ formErrors.password }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_gender')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="multiselect-gender"
            v-model="promoter_leader.genderId"
            placeholder="Select Gender"
            :searchable="true"
            :options="genders"
            label="text"
            track-by="id"
            select-label
            deselect-label
            :loading="isGendersLoading"
            @input="formErrors.genderId = ''"
          ></Multiselect>
          <span v-if="formErrors.genderId" class="invalid-field">{{ formErrors.genderId }}</span>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_salesperson_email')"></label>
          <span class="required-star">*</span>
          <input
            v-model="promoter_leader.username"
            type="email"
            class="form-control"
            placeholder
            @keyup="formErrors.username = ''"
          />
          <span v-if="formErrors.username" class="invalid-field">{{ formErrors.username }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="country"
            v-model="promoter_leader.countryId"
            :searchable="false"
            :options="countryOptions"
            label="text"
            track-by="id"
            :loading="isCountriesLoading"
            :allow-empty="false"
            :show-labels="false"
            @input="formErrors.country = ''"
          ></Multiselect>
          <span v-if="formErrors.country" class="invalid-field">{{ formErrors.country }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_manager_contact_number')"></label>
          <span class="required-star">*</span>
          <input
            id="phoneNumber"
            v-model="promoter_leader.phoneNumber"
            name="Phone Number"
            type="number"
            class="form-control"
            placeholder
            @keyup="formErrors.phoneNumber = ''"
          />
          <span v-if="formErrors.phoneNumber" class="invalid-field">{{ formErrors.phoneNumber }}</span>
        </div>
      </div>
    </div>
    <div class="w-100 text-right mt-4">
      <button class="btn btn-sm btn-primary" :disabled="isPromoterLeaderSubmitting" @click="submitPromoterLeader" v-text="$ml.get('button_submit_text')"></button>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { PROMOTER_LEADER_REGISTRATION_SUBMIT } from "../../store/modules/admin/actions";
import { GENDER_LIST_REQUEST } from "../../store/modules/utility/actions";
import { phoneValidate, emailValidate, passwordValidate } from "../../utils/helpers";

export default {
  name: "PromoterLeaderRegistration",
  components: {
    Multiselect: Multiselect,
  },
  props: {
    countryOptions: {type: Array, default: () => []},
    isCountriesLoading: {type: Boolean, default: false}
  },
  data(){
    return{
      promoter_leader: {},
      formErrors: [],
      isPromoterLeaderSubmitting: false,
      genders: [],
      isGendersLoading: false,
    }
  },
  methods: {
    getGenders() {
      this.isGendersLoading = true;
      this.$store.dispatch(GENDER_LIST_REQUEST).then(res => {
        if (res.content && res.resultCode === 0) {
          this.genders = res.content;
          this.isGendersLoading = false;
        } else {
          this.isGendersLoading = false;
        }
      });
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.promoter_leader.username) {
        this.formErrors.username = this.$ml.get('form_dealer_email_required');
        error++;
      }

      if(this.promoter_leader.username){
        if(!emailValidate(this.promoter_leader.username)){
          this.formErrors.username = this.$ml.get('form_dealer_email_invalid');
          error++;
        }
      }

      if (!this.promoter_leader.password) {
        this.formErrors.password = this.$ml.get('form_field_password_required');
        error++;
      }

      if (!this.promoter_leader.countryId) {
        this.formErrors.country = this.$ml.get('form_outlet_country_required');
        error++;
      }

      if (!this.promoter_leader.genderId) {
        this.formErrors.genderId = this.$ml.get('form_salesperson_gender_required');
        error++;
      }

      if (!this.promoter_leader.fullName) {
        this.formErrors.fullName = this.$ml.get('form_manager_name_required');
        error++;
      }

      if (!this.promoter_leader.phoneNumber) {
        this.formErrors.phoneNumber = this.$ml.get('form_salesperson_contact_number_required');
        error++;
      }

      if (this.promoter_leader.phoneNumber) {
        if(!phoneValidate(this.promoter_leader.phoneNumber)){
          this.formErrors.phoneNumber = this.$ml.get('invalid_phone_number');
          error++;
        }
      }

      return !error;
    },
    submitPromoterLeader(e){
      this.isPromoterLeaderSubmitting = true;
      if (!this.checkFormValidity(e)) {
        this.isPromoterLeaderSubmitting = false;
        return;
      } 
      if(!passwordValidate(this.promoter_leader.password)) {
        this.formErrors.password = this.$ml.get('form_field_password_validation');
        return
      }

      let promoter_leader = {...this.promoter_leader};
      promoter_leader["countryId"] = this.promoter_leader.countryId.id;
      promoter_leader["genderId"] = this.promoter_leader.genderId.id;
      this.$store.dispatch(PROMOTER_LEADER_REGISTRATION_SUBMIT, promoter_leader).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Promoter Leader " + this.promoter_leader.username + " is created",
            timeOut: true
          });
          this.promoter_leader = {};
        }
        this.isPromoterLeaderSubmitting = false;
      });
    }
  },
  mounted(){
    this.getGenders();
  }
}
</script>
<template>
  <div>
    <div class="row">
      <!-- <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('staff_list_type')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="type"
            v-model="reset_password.type"
            :searchable="false"
            :options="types"
            label="name"
            track-by="value"
            :allow-empty="false"
            :show-labels="false"
            @input="formErrors.type = ''; getStaffList()"
          ></Multiselect>
          <span v-if="formErrors.type" class="invalid-field">{{ formErrors.type }}</span>
        </div>
      </div> -->
      <div class="col-lg-12">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_salesperson_email')"></label>
          <span class="required-star">*</span>
          <input
            id="username"
            v-model="reset_password_web.username"
            name="Username"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.username = ''"
          />
          <!-- <Multiselect
            ref="email"
            v-model="reset_password.email"
            :searchable="true"
            :options="staffList"
            label="value"
            track-by="value"
            :loading="isStaffListLoading"
            :allow-empty="false"
            :show-labels="false"
            @input="formErrors.email = ''"
          ></Multiselect> -->
          <span v-if="formErrors.username" class="invalid-field">{{ formErrors.username }}</span>
        </div>
      </div>
    </div>
    <div class="w-100 text-right mt-4">
      <button class="btn btn-sm btn-primary" :disabled="isResetPasswordWebSubmitting" @click="submitResetPasswordWeb" v-text="$ml.get('button_submit_text')"></button>
    </div>
  </div>
</template>
<script>
// import Multiselect from "vue-multiselect";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { RESET_PASSWORD_WEB_REQUEST } from "../../store/modules/admin/actions";
import { emailValidate } from "../../utils/helpers";
// import { GET_STAFF_LIST_REQUEST } from "../../store/modules/staff/actions";

export default {
  name: "ResetPasswordWeb",
  components: {
    // Multiselect: Multiselect,
  },
  data(){
    return{
      reset_password_web: {
        username: "",
      },
      // types: [
      //   {name: "Leader", value: "leader"},
      //   {name: "Promoter", value: "promoter"},
      //   {name: "Salesperson", value: "salesperson"},
      //   {name: "State Manager", value: "stateManager"},
      //   {name: "Regional Manager", value: "regionalManager"},
      // ],
      // staffList: [],
      formErrors: [],
      isResetPasswordWebSubmitting: false,
      // isStaffListLoading: false
    }
  },
  methods: {
    // getStaffList(){
    //   this.isStaffListLoading = true;
    //   this.staffList = [];
    //   let pdata = {};
    //   pdata.type = this.reset_password.type.value + "s";
    //   this.$store.dispatch(GET_STAFF_LIST_REQUEST, pdata).then(res => {
    //     if (res.resultCode === 0 && res.content) {
    //       this.staffList = res.content.map(el => {return {value: el.email}});
    //       this.isStaffListLoading = false;
    //     }
    //   });
    // },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.reset_password_web.username) {
        this.formErrors.username = this.$ml.get('form_dealer_email_required');
        error++;
      }

      if(this.reset_password_web.username){
        if(!emailValidate(this.reset_password_web.username)){
          this.formErrors.username = this.$ml.get('form_dealer_email_invalid');
          error++;
        }
      }

      return !error;
    },
    submitResetPasswordWeb(e){
      this.isResetPasswordWebSubmitting = true;
      if (!this.checkFormValidity(e)) {
        this.isResetPasswordWebSubmitting = false;
        return;
      } else {
        let reset_password_web = {...this.reset_password_web};
        // reset_password_web.type = reset_password_web.type.value;
        // reset_password_web.email = reset_password_web.email.value;
        this.$store.dispatch(RESET_PASSWORD_WEB_REQUEST, reset_password_web).then(res => {
          if (res.resultCode === 0) {
            this.reset_password_web = {};
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Reset password is successfull",
              timeOut: true
            });
          }
          this.isResetPasswordWebSubmitting = false;
        });
      }
    }
  },
  mounted(){
    // this.getStaffList();
  }
}
</script>
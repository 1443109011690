<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_salesperson_email')"></label>
          <span class="required-star">*</span>
          <input
            v-model="country_head.username"
            type="email"
            class="form-control"
            placeholder
            @keyup="formErrors.username = ''"
          />
          <span v-if="formErrors.username" class="invalid-field">{{ formErrors.username }}</span>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_field_password')"></label>
          <span class="required-star">*</span>
          <input
            v-model="country_head.password"
            type="password"
            class="form-control"
            placeholder
            @keyup="formErrors.password = ''"
          />
          <span v-if="formErrors.password" class="invalid-field">{{ formErrors.password }}</span>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="country"
            v-model="country_head.countryId"
            :searchable="false"
            :options="countryOptions"
            label="text"
            track-by="id"
            :loading="isCountriesLoading"
            :allow-empty="false"
            :show-labels="false"
            @input="formErrors.country = ''"
          ></Multiselect>
          <span v-if="formErrors.country" class="invalid-field">{{ formErrors.country }}</span>
        </div>
      </div>
    </div>
    <div class="w-100 text-right mt-4">
      <button class="btn btn-sm btn-primary" :disabled="isCountryHeadSubmitting" @click="submitCountryHead" v-text="$ml.get('button_submit_text')"></button>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { COUNTRY_HEAD_REGISTRATION_SUBMIT } from "../../store/modules/admin/actions";
import { emailValidate, passwordValidate } from "../../utils/helpers";

export default {
  name: "CountryHeadRegistration",
  components: {
    Multiselect: Multiselect,
  },
  props: {
    countryOptions: {type: Array, default: () => []},
    isCountriesLoading: {type: Boolean, default: false}
  },
  data(){
    return{
      country_head: {},
      formErrors: [],
      isCountryHeadSubmitting: false,
    }
  },
  methods: {
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.country_head.username) {
        this.formErrors.username = this.$ml.get('form_dealer_email_required');
        error++;
      }

      if(this.country_head.username){
        if(!emailValidate(this.country_head.username)){
          this.formErrors.username = this.$ml.get('form_dealer_email_invalid');
          error++;
        }
      }

      if (!this.country_head.password) {
        this.formErrors.password = this.$ml.get('form_field_password_required');
        error++;
      }

      if (!this.country_head.countryId) {
        this.formErrors.country = this.$ml.get('form_outlet_country_required');
        error++;
      }

      return !error;
    },
    submitCountryHead(e){
      this.isCountryHeadSubmitting = true;
      if (!this.checkFormValidity(e)) {
        this.isCountryHeadSubmitting = false;
        return;
      }
      if(!passwordValidate(this.country_head.password)) {
        this.formErrors.password = this.$ml.get('form_field_password_validation');
        return
      }

      let country_head = {...this.country_head};
      country_head["countryId"] = this.country_head.countryId.id;
      this.$store.dispatch(COUNTRY_HEAD_REGISTRATION_SUBMIT, country_head).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Country Head " + this.country_head.username + " is created",
            timeOut: true
          });
          this.country_head = {};
        }
        this.isCountryHeadSubmitting = false;
      });
    }
  },
  mounted(){}
}
</script>